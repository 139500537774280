export const BASE_PATH = process.env.REACT_APP_BASE_PATH;
export const PEARBEE_VERIFY = BASE_PATH + "-auth/verify";
export const GENERATE_PAYLOAD = "ton-proof/generatePayload";
export const CHECK_PROOF = "ton-proof/checkProof";
export const GET_USER_BALANCE = BASE_PATH + "/getUsersBalance";
export const REFRESH_TOKEN_URL = "auth/exchange-token";
export const FORGOT_PASSWORD = "user/forgotpwd";
export const CHANGE_PASSWORD = "user/changepwd";
export const RESET_PASSWORD = "user/resetpwd";
export const COMMON_DATA = "common";
export const UPLOAD_PHOTOS = "common/images";
export const REMOVE_IMAGE = "remove-image";
export const USERS_FILTERS = "user/filter-users";
export const LIVE_QUESTIONS = BASE_PATH + "app/v1/getLiveQuestion_app";
export const CATEGORY = BASE_PATH + "app/getCategories_app";
export const CATEGORYBYID = BASE_PATH + "app/getCategoriesById?categoriesId=";
export const CATEGORY_QUESTION =
  BASE_PATH + "app/v1/getLiveQuestionByCatgory_app?categoriesId=";
export const ROOM_CATEGORY_QUESTION =
  BASE_PATH + "app/v1/getLiveQuestionByCatgory_app?";
export const CATEGORY_QUESTION_BY_ID =
  BASE_PATH + "app/v1/getQuestionsById_App?questionId=";
export const CONFIRM_BED = BASE_PATH + "/v1/confirmBet";
export const INVITE_CODE = BASE_PATH + "/v1/UGCQuestionsById?inviteCode=";
export const TRANSACTIONS = BASE_PATH + "/getuserInAppTransaction_App";
export const WITHDRAW_TRANSACTIONS = BASE_PATH + "/withdrawTransactions";
export const DEPOSIT_TRANSACTIONS = BASE_PATH + "/getUserFiatTransaction_App";
export const UGC_CREATE_QUESTION = BASE_PATH + "/v1/UGCQuestionByUser";
export const UGC_UPDATE_QUESTION =
  BASE_PATH + "/updateUGCQuestionByUser?question_id=";
export const UGC_SET_ANSWER = BASE_PATH + "/admin/setAnswer";
export const UGC_CANCEL_QUESTION = BASE_PATH + "/admin/cancelQuestion";

export const PORTFOLIO_LIVE_QUESTIONS = BASE_PATH + "/getProtofolioLive";
export const PORTFOLIO_CLOSED_QUESTIONS = BASE_PATH + "/getProtofolioComplete";
export const GET_PORTFOLIO_LIVE_DETAILS =
  BASE_PATH + "/getProtofolioLiveDetails?questionId=";
export const GET_USER_BAL = BASE_PATH + "/getUsersBalance";
export const GET_COMPLETED_DETAILS =
  BASE_PATH + "/getProtofolioCompleteDetails?questionId=";
export const QUESTION_TRADES =
  BASE_PATH + "/getProtofolioCompletebyQuestion?questionId=";
export const WALLET_PURCHASE = BASE_PATH + "/purchase-items";
export const GET_BANNER = BASE_PATH + "app/getBanner_app";
export const LEADER_BOARD = BASE_PATH + "/leaderboard?type=0";
export const GET_EXPIRED_QUESTIONS = BASE_PATH + "/UGCExpiredQuestions";
export const USER_TRADE_QUESTIONS = BASE_PATH + "/userTradeUGCQuestion";
export const GET_PARTICIPATED_QUESTIONS = BASE_PATH + "/UGCJoinedQuestions";
export const GET_QUESTION_BY_ID =
  BASE_PATH + "/updateUGCQuestionByUser?question_id=";
export const ROOMS_LIST = BASE_PATH + "app/room";
export const ROOM_DETAILS = BASE_PATH + "/room-status?roomId=";
export const ROMM_STATUS = BASE_PATH + "/room";
export const ZEGO_TOKEN = BASE_PATH + "/zego-token";
export const AUDEINCE_ROOM_JOIN = BASE_PATH + "/room";
export const HOST_ROOM_JOIN = BASE_PATH + "/create-room";
export const ROOM_LEAVE = BASE_PATH + "/room";
export const UGC_CONFIG = BASE_PATH + "/ugc-config";
export const GOLD_QUESTION = BASE_PATH + "/get-gold-question";
export const ANSWER_GOLD_QUESTION = BASE_PATH + "/answer-gold-question";

// un-used
export const LOGIN_URL = "/auth/userLogin";
export const SIGN_UP = "/auth/signup-wallet";
