import { lazy, Suspense, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { connect, useSelector } from "react-redux";
import mixpanel from "mixpanel-browser";
import {
  appLanchedAction,
  loginAction,
  setUserBalance,
  userWalletActions,
} from "core/redux/account/account.action";
import { localStorage } from "core/helper";
import { Loading } from "components/common/loading";
import { GET_USER_BAL } from "core/service/api.url.service";
import { services } from "core/service";

const PostLogin = lazy(() => import("../postlogin"));
const Prelogin = lazy(() => import("../prelogin"));

const Container = (props) => {
  const [isMount, setIsMount] = useState(false);
  let tokenId = process.env.REACT_APP_MIX_PANEL; // mix-panel-tokenid
  const { loginAction, appLanchedAction, setUserBalance } = props;
  const isLoggedIn = useSelector(
    (state) => state.account?.authUser?.isLoggedIn
  );
  const authUser = useSelector((state) => state?.account?.authUser);
  const appLaunched = useSelector((state) => state.account?.appLaunched);
  const reloadWallet = useSelector((state) => state.account?.reloadWallet);

  mixpanel.init(tokenId, {
    debug: false,
    track_pageview: true,
    persistence: "localStorage",
    userId: authUser?.data?.userId,
  });

  const checkSessionlogin = async () => {
    const token = localStorage.getAuthToken();
    const appLaunched = localStorage.getAppLanched();
    appLanchedAction({
      appLaunched: appLaunched?.appLaunched,
    });
    if (token === "") {
      return false;
    }
    const authUser = localStorage.getAuthUser();
    loginAction(authUser);
  };

  const getUserBalance = async () => {
    try {
      const resp = await services.get(GET_USER_BAL);
      if (resp?.result) {
        setUserBalance(resp?.result?.[0]);
      }
    } catch (error) {
      console.log("err::", error  );
    }
  };

  useEffect(() => {
    if (isMount) {
      if (isLoggedIn) {
        getUserBalance();
      }
    }
  }, [isMount, isLoggedIn, reloadWallet]);

  useEffect(() => {
    checkSessionlogin();
  }, []);

  useEffect(() => {
    setIsMount(true);
  }, []);

  const LoadFallback = () => {
    return (
      <div className="pearbee_fallback_loading_wrap">
        <Loading variant="light" />
      </div>
    );
  };
  // console.log("Score::", score);

  return (
    <div
      data-typography="poppins"
      data-theme-version="light"
      data-layout="vertical"
      data-nav-headerbg="color_1"
      data-headerbg="color_1"
      data-sidebar-style="full"
      data-sibebarbg="color_1"
      data-sidebar-position="fixed"
      data-header-position="fixed"
      data-container="wide"
      direction="ltr"
      data-primary="color_1"
      data-sibebartext="color_1"
    >
      <Suspense fallback={<LoadFallback />}>
        {appLaunched && (
          <div className="after-login">
            {/* <BootstrapContainer> */}
              <PostLogin />
            {/* </BootstrapContainer> */}
          </div>
        )}
        {!appLaunched && <Prelogin />}
      </Suspense>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginAction: (authUser) => dispatch(loginAction(authUser)),
    userWalletActions: (wallet) => dispatch(userWalletActions(wallet)),
    appLanchedAction: (appLanched) => dispatch(appLanchedAction(appLanched)),
    setUserBalance: (user) => dispatch(setUserBalance(user)),
  };
};

export default connect(null, mapDispatchToProps)(Container);

// import axios from "axios";
// import { useSearchParams } from "react-router-dom";
// import { GET_USER_BAL, PEARBEE_VERIFY } from "core/service/api.url.service";
// import { services } from "core/service";
// import NotFound from "components/common/not-found";

// const [getTokenParams] = useSearchParams();
// const [validateToken, setValidateToken] = useState(false);
// const [verifyLoading, setVerifyLoading] = useState(false);
// const token = useSelector((state) => state.account?.token);

// let tokenUrl = getTokenParams.get("token");
// let refreshTokenUrl = getTokenParams.get("refreshToken");

// const loadPearbeeVerfify = async (token, refreshToken) => {
//   try {
//     // const category = await Services.post(`${CATEGORYBYID}${id}`);
//     setVerifyLoading(true);
//     let urlPlbOrVeeraCheck = PEARBEE_VERIFY;
//     const authUrl = process.env.REACT_APP_API_URL + urlPlbOrVeeraCheck;
//     const resp = await axios.get(authUrl, {
//       headers: {
//         Token: token,
//       },
//     });
//     if (resp?.status) {
//       const { data } = resp;
//       loginAction({
//         isLoggedIn: true,
//         token: data.jwt_token,
//         authUser: data,
//       });
//       setValidateToken(true);
//       setVerifyLoading(false);
//     } else {
//       setValidateToken(false);
//       setVerifyLoading(false);
//     }
//   } catch (err) {
//     setValidateToken(false);
//     setVerifyLoading(false);
//   }
// };

// useEffect(() => {
//   if (isComponentMounted) {
//     let token = getTokenParams.get("token");
//     if (!isLoggedIn && (refreshToken || token)) {
//       loadPearbeeVerfify(token, refreshToken);
//     }
//   }
// }, [getTokenParams, isLoggedIn, isComponentMounted]);

// useEffect(() => {
//   const handleChange = async () => {
//     utils.mixPannelEvent("home_page", "HOME_PAGE", "home_page");
//   };
//   if (isComponentMounted) {
//     handleChange();
//   }
// }, [isComponentMounted]);

// useEffect(() => {
//   if (isLoggedIn && (tokenUrl || refreshTokenUrl)) {
//     getTokenParams.delete("token");
//     getTokenParams.delete("refreshTokenUrl");
//   }
// }, [tokenUrl, refreshTokenUrl, isLoggedIn]);
