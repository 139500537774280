import React, { createContext, useContext, useEffect, useState } from "react";
import { getAuthToken } from "core/helper/localstorage";
import io, { Manager } from "socket.io-client";
import { useParams } from "react-router-dom";

const socketURL = "wss://tma-api.pearbee.com/?room_id=";
const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [error, setError] = useState(null);
  const [roomId, setRoomId] = useState("");

  useEffect(() => {
    const newSocket = io(socketURL + roomId, {
      transports: ["websocket", "polling"],
      auth: {
        token: getAuthToken(),
      },
    }); // Replace with your server URL

    newSocket.on("connect", () => {
      console.log("Connected to socket server");
    });

    newSocket.on("connect_error", (err) => {
      console.error("Connection Error:", err.message);
      setError(err.message);
    });

    newSocket.on("connect_timeout", () => {
      console.error("Connection Timeout");
      setError("Connection Timeout");
    });

    newSocket.on("error", (err) => {
      console.error("Socket Error:", err.message);
      setError(err.message);
    });

    newSocket.on("disconnect", (reason) => {
      console.log("Disconnected:", reason);
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, [roomId]);

  return (
    <SocketContext.Provider value={{ socket, error, setRoomId }}>
      {children}
    </SocketContext.Provider>
  );
};
