import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "../node_modules/react-datepicker/dist/react-datepicker.css";
import "swiper/css";
import "../node_modules/swiper/swiper-bundle.css";
import "./assets/styles/index.css";
import { Provider } from "react-redux";
import Container from "./pages/container";
import store from "./core/store";
// import { ZIM } from "zego-zim-web";
import ZIMProvider from "context/ZimServicesContext";
import { SocketProvider } from "context/SocketContext";
// import {
//   Web3OnboardProvider,
//   init,
//   useConnectWallet,
// } from "@web3-onboard/react";
// import injectedModule from "@web3-onboard/injected-wallets";
// import { useEffect } from "react";
import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { polygon, polygonAmoy, baseSepolia, sepolia } from "wagmi/chains";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { defineChain } from "viem";

// const chains = [
//   {
//     id: "0x89",
//     token: "MATIC",
//     label: "Matic Mainnet",
//     rpcUrl: "https://matic-mainnet.chainstacklabs.com",
//   },
//   {
//     id: "0x13882",
//     token: "MATIC",
//     label: "Matic Amoy",
//     rpcUrl: "https://matic-amoy.chainstacklabs.com",
//   },
// ];
// const wallets = [injectedModule()];
// const web3Onboard = init({
//   wallets,
//   chains,
//   appMetadata: {
//     name: "Web3-Onboard Demo",
//     icon: "<svg>App Icon</svg>",
//     description: "A demo of Web3-Onboard.",
//   },
//   connect: {
//     autoConnectLastWallet: true, // Automatically reconnects to the last used wallet
//   },
// });

// const defaultChains = defineChain({
//   id: "0x13882",
//   token: "MATIC",
//   label: "Matic Amoy",
//   rpcUrl: "https://matic-amoy.chainstacklabs.com",
// });

// let polygonChainType =
//   process.env.REACT_APP_ENABLE_TESTNETS === "true" ? polygonAmoy : polygon;
// console.log("polygonChainType::", polygonChainType);

// let baseSepoliaChainType =
//   process.env.REACT_APP_ENABLE_TESTNETS === "true" ? sepolia : baseSepolia;
// console.log("baseSepoliaChainType::", baseSepoliaChainType  );

  
const config = getDefaultConfig({
  appName: "Pearbee Web3",
  projectId: "164602c40a4abbbe3af343b1534e0b4d", 
  chains: [baseSepolia],
});
const queryClient = new QueryClient();

function App({ children }) {
  return (
    <div className="app">
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider>
            {/* <Web3OnboardProvider web3Onboard={web3Onboard}> */}
            <SocketProvider>
              <ZIMProvider>
                <Provider store={store}>
                  <Container />
                </Provider>
              </ZIMProvider>
            </SocketProvider>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
      {/* </Web3OnboardProvider> */}
    </div>
  );
}

export default App;
